import "../../assets/scss/styler_dynamic.scss";
import "../../assets/scss/positions.scss";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { ImageLoader } from '../Common/ImageLoader.jsx';
import {Link} from "react-router-dom";

import './SingleProduct.scss';

const SingleProduct = ({ product,dealer_id}) => {
  const image_src = product.image1??(product.image2??product.image3);
  return (
    <Link to={`/shop/${dealer_id}/viewProduct/${product.id}`}  className="single_product">
       <div className="product_image_container">
      {image_src ? (
        <div className="product_image_div" style={{ backgroundImage: `url(${process.env.REACT_APP_CDN}/${image_src})`}}>

         </div>
            ) : <Skeleton width="100%" height="100%" />}
      {/*<div className="image_ghost"></div>*/}
      </div>
        <div className="product_name">{product.label || <Skeleton width={80} />}</div>
        <div className="product_price">
          <i className="fa fa-rupee-sign"></i>{product.total}
          <span 
            style={{display : product.unit?'inline':'none'}} className="product_unit">/{product.unit}</span>
        </div>
        <div className="product_brand">{product.brand_name}</div>
        <div className="view_button_container" >
          <div className="view_button"><i className="fas fa-search"></i>&nbsp;VIEW</div>
        </div>

    </Link>
  );
}

export default SingleProduct;
