import './BottomNavbar.scss';

import {Link} from "react-router-dom";
import React, { Component } from "react";

import MyContext from '../MyContext.jsx';



class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
   
render(){
  return (
    <MyContext.Consumer>
      {context => (
      <div className="bottom_nav_placeholder">
        <div className="bottom_nav">
          <Link className="grid_item" to={`/shop/${this.props.match.params.did}`}>
          <i className="fas fa-home"></i>
          </Link>
          <div style={{display:'grid'}}>
            <div className="seperator"></div>
          </div>
          <Link className="grid_item" to={`/shop/${this.props.match.params.did}/cart`}>
          <i className="fa fa-shopping-bag"></i>
          </Link >
        </div>
      </div>
      )}
    </MyContext.Consumer>
  )}
}

export default Footer;