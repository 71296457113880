// import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
// import { ImageLoader } from '../Common/ImageLoader.jsx';
import React, { Component } from "react";
import $ from 'jquery';
import axios from "axios";
import MyContext from '../MyContext.jsx';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import './ViewProduct.scss';
import image from "../../assets/images/image_placeholder.png";

export default class ViewProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productDetails : { 
        qty : 1,
        addons : []
      },
      image_src_arr : [],
      display_image_src : "",

    };
    this.set1=setTimeout(0);
    this.set2=setTimeout(0);
    this.productDetails={};
    this.default_variants=[];
  } 

  componentDidMount() {
    const data = new URLSearchParams();
    data.append('dealer_token',this.props.match.params.did);
    data.append('product_id',this.props.match.params.pid);
    const url = `${process.env.REACT_APP_SSS}/api/getProductDetails`;
    axios.post(url,data)
      .then(response => {
        // console.log(response.data);
        this.setProductDetails(response.data,this);
      });
  }

  changeImage(src){
    this.setState({display_image_src: src});
  }

  addonChange(e,addon){
    if(e.target.checked){
      addon.is_checked = 1;
      this.state.productDetails.total=parseFloat(+this.state.productDetails.total + +addon.price).toFixed(2);
      this.setState(this.state.productDetails);
    }
    else{
      addon.is_checked = 0;
      this.state.productDetails.total=parseFloat(+this.state.productDetails.total - +addon.price).toFixed(2);
      this.setState(this.state.productDetails);
    }
  } 

  changeVariant(e,props){
    var label_id = e.target.getAttribute("label_id");
    var value_id = e.target.getAttribute("value_id");
    var label = e.target.getAttribute("label");
    var value = e.target.textContent;
    var dealer_token = this.props.match.params.did;
    var obj_this = this;
    //Dimmed values variant check
    if(e.target.getAttribute("dimmed")){
       const url = `${process.env.REACT_APP_SSS}/api/getDimmedClosest`;
       $(".ghost").css("display","block");
        $.ajax({
          url : url,
          data :{
            dealer_token  :  dealer_token,
            variation_value_id : value_id

          },
          type : "post"
        }).done(function(return_data){
          // console.log(return_data); 
          if( "id" in return_data){
            props.history.replace(`/shop/${props.match.params.did}/viewProduct/${return_data.id}`);
            obj_this.setProductDetails(return_data,obj_this);
          }else{
            alert("Sorry currently there are no product with "+label+" "+value);
          }
          $(".ghost").css("display","none");
        });
      return;
    }

    var new_product_variation_values = JSON.parse(JSON.stringify(this.state.productDetails.default_variation_values));
    new_product_variation_values.forEach(function(variation,index){
      if(variation.label_id == label_id && variation.value_id != value_id){
        variation.value_id = value_id;
        variation.dimmed = 1;
        const url = `${process.env.REACT_APP_SSS}/api/getVariant`;
        $(".ghost").css("display","block");
        $.ajax({
          url : url,
          data :{
            variant_variations :  new_product_variation_values,
            dealer_token   :  dealer_token,
            variation_labels : obj_this.state.productDetails.variation_labels
          },
          type : "post"
        }).done(function(return_data){
          // console.log(return_data); 
          if( "id" in return_data){
            props.history.replace(`/shop/${props.match.params.did}/viewProduct/${return_data.id}`);
            obj_this.setProductDetails(return_data,obj_this);
          }else{
            alert("Sorry currently there are no product with "+label+" "+value);
          }
          $(".ghost").css("display","none");
        });
      }
    });
  }

  setProductDetails(productDetails,obj_this){
    productDetails.qty=1;
    const image_src_arr =[];
    image_src_arr.push(productDetails.image1,productDetails.image2,productDetails.image3);
    const src_arr = image_src_arr.filter(el => el != null);
    obj_this.setState({
      productDetails : productDetails,
      image_src_arr : src_arr,
      display_image_src :src_arr[0]
    }); 
    $(".single_variation_value_container").removeClass("selected_variation_value").addClass("unselected_variation_value").css("opacity",1).attr("dimmed","");;
    for(let i=0;i<productDetails.default_variation_values.length;i++){
      $(".single_variation_value_container[value_id="+productDetails.default_variation_values[i]['value_id']+"]").removeClass("unselected_variation_value").addClass("selected_variation_value");
    }
    for(var i=0;i<productDetails.variations_with_no_product.length;i++){
      $(".single_variation_value_container[value_id="+productDetails.variations_with_no_product[i]['id']+"]").css("opacity",0.5).attr("dimmed",true);
    }
  }


  
  addProduct(cart_items,updateCart){
    const item = cart_items.filter(item => item.id == this.state.productDetails.id );
    if(item.length){
      const addon = item[0]['addons'].filter(addon => addon.is_checked == 1 );
      const current_product_addon = this.state.productDetails.addons.filter(addon => addon.is_checked ==1);
      if(addon.length || current_product_addon.length){
        const productDetails = $.extend(true,{},this.state.productDetails);
        cart_items.push(productDetails);
      }else{
        item[0]['qty'] = +item[0]['qty'] + +this.state.productDetails.qty ;
      }
    }else{
      const productDetails = $.extend(true,{},this.state.productDetails);
      cart_items.push(productDetails);
    }
    // alert("Item added to cart");
    clearTimeout(this.set1);
    clearTimeout(this.set2);
    var add_position = $(".add_to_cart_button").offset();
    var add_position_top = add_position.top - $(window).scrollTop();
    // Before Animation
    $(".addCartButton").css({
      'display' : 'grid',
      'transition' : 'none',
      'height'  : $(".add_to_cart_button").outerHeight(),
      'width'  : $(".add_to_cart_button").outerWidth(),
      'left' : add_position.left,
      'top' : add_position_top,
      'opacity' : 1,
      'background-color' : 'green'
    });
    var cart_position = $(".cart").offset();
    this.set1 = setTimeout(function(){
    // During Animation
       $(".addCartButton").css({
            'left' : cart_position.left,
            'top' : cart_position.top- $(window).scrollTop(),
            'transition' : 'all 1.5s',
            'height'  : $(".cart").outerHeight(),
            'width'  : $(".cart").outerWidth()+10,
            'opacity' : 0.4,
            'background-color' : 'orangered'
          })
    },1);
    this.set2 = setTimeout(function(){
    // Animation End
      $(".addCartButton").css({
          'transition' : 'none',
          'display':"none"
      });
      updateCart();

    },1501)
  }
  render(){
    return (
      <MyContext.Consumer>
        {context => (
          <div className="view_product_block pages"  role="presentation">
            <div className="image_block">
              <div className="image_container">
                <div className="product_image" 
                  style={{ backgroundImage: this.state.image_src_arr.length?`url(${process.env.REACT_APP_CDN}/${this.state.display_image_src})`:`url(${image}`}}>
                </div>
                <div className="other_images_container" 
                  style={{display : this.state.image_src_arr.length==1?'none':'block'}}>
                  {this.state.image_src_arr &&
                  this.state.image_src_arr.map((src,index) =>(
                  <div className="images" 
                    key={index}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_CDN}/${src})`,
                      border : `${src==this.state.display_image_src?'2px solid #d72727':'1px solid grey'}`
                    }} 
                    onClick={() => {this.changeImage(src);}}
                    ></div>
                  ))}
                </div>
              </div>
            </div>

            <div className="details_block">
              <div className="details_container">
                <div className="product_name">{this.state.productDetails.label}</div>
                <div className="product_brand">Brand : {this.state.productDetails.brand_name}</div>
                <div className="product_price_container">
                  <i className="fa fa-rupee-sign"></i>
                  <span className="product_price"> {this.state.productDetails.total}</span>
                  <span style={{display : this.state.productDetails.unit?'inline':'none'}} className="product_unit"> / {this.state.productDetails.unit}</span>
                </div>
                <div className="product_rating">
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star-half-alt"></i>
                </div>
                {this.state.productDetails.variation_labels &&  
                  this.state.productDetails.variation_labels.map((label,index) =>(
                  <div className="variants_container" key={label.id}>
                    <div className="variant_name">{label.variation_label}</div>
                    <div className="variation_values_container">
                      {label.values &&
                        label.values.map((value,idx) => (
                           <div className="single_variation_value_container unselected_variation_value" 
                            key={idx}
                            label_id={label.id}
                            label = {label.variation_label}
                            value_id={value.id}
                            onClick={e => {this.changeVariant(e,this.props)}}
                             >
                             {value.variation_value}
                           </div>

                      ))}
                      </div>
                  </div>
                ))}
                  
                
                <div className="description_title" style={{
                  visibility:this.state.productDetails.specification?'visible':'hidden'}}>Description</div>
                <div className="product_description">{this.state.productDetails.specification}</div>
                <div className="addons_container" 
                style={{display:this.state.productDetails.addons.length?'grid':'none'}}>
                <div className="addons_title">Addons</div>
                <div></div>
                  {this.state.productDetails.addons &&
                    this.state.productDetails.addons.map((addon,index) =>(
                    <label className="single_addon_container"  key = {addon.id}>
                      <label>{addon.name}&nbsp; <i className="fa fa-rupee-sign"></i>{addon.price}</label>   
                      <Checkbox
                        style={{
                            transform: "scale(1.2)",
                        }}
                        onChange={e => {this.addonChange(e,addon)}}
                        value={addon.id}
                      />
                    </label>
                  ))}
                </div>
                <div className="qty_container">
                  <button onClick={() => {this.state.productDetails.qty=--this.state.productDetails.qty>0?this.state.productDetails.qty:1;this.setState(this.state.productDetails);}}><i className="fa fa-minus"></i></button>
                  <input className="product_qty" 
                    type="number" 
                    value={this.state.productDetails.qty} 
                    onChange={e => {this.state.productDetails.qty = e.target.value;this.setState(this.state.productDetails); }} 
                    onBlur={e => {this.state.productDetails.qty = e.target.value>0?e.target.value:1; this.setState(this.state.productDetails);}} 
                  />
                  <button onClick={() => {this.state.productDetails.qty++;this.setState(this.state.productDetails);}}><i className="fa fa-plus"></i></button>
                </div><br/>
                <button 
                  style={{display: this.state.productDetails.name?'block':'none'}}
                  className="add_to_cart_button" 
                  onClick={() => { this.addProduct(context.cart_items,context.updateCart)}} ><i className="fa fa-shopping-bag"></i> Add to cart</button>
                <div className="addCartButton">{this.state.productDetails.qty}</div>
              </div>
            </div>
            <div className="ghost"></div>
          </div>
          )}
      </MyContext.Consumer>
    );
  }
}

ViewProduct.contextType = MyContext;
// export default ViewProduct;
