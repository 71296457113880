import logo from "../../assets/images/Digistore_Logo_LQ_2.png";
import './Header.scss';
import Select from 'react-select';
import {Link} from "react-router-dom";
import React, { Component } from "react";
// import { useHistory } from "react-router-dom";
import axios from "axios";
import MyContext from '../MyContext.jsx';
import $ from 'jquery';



class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories : [],
      selectedOption : null,
      logo : ''
    };
  }
   
  componentDidMount(){
    const data = new URLSearchParams();
    data.append('dealer_token',this.props.match.params.did);
    axios.post(`${process.env.REACT_APP_SSS}/api/getDealerCategories`,data)
      .then((response) => {
        // console.log(response.data);
        const categories = response.data.categories;
        const obj = { value:"",label:'ALL PRODUCTS'}
        categories.unshift(obj);
        const logo_src = response.data.dealer_details.logo?process.env.REACT_APP_CDN+'/'+response.data.dealer_details.logo:logo;
        this.setState({logo : logo_src});
        this.context.setDealerDetails(response.data.dealer_details); 
        $("title").html(response.data.dealer_details.name);
        this.setState({
          categories : categories,
        });
      })
  }
  categoryChange(options,props){
    // console.log(options);
    if(!options.value){
      this.setState({ selectedOption : null});
      props.history.push(`/shop/${props.match.params.did}`); 
      return;   
    }
    props.history.push(`/shop/${props.match.params.did}/category/${options.value}`);
    props.history.push(`/shop/${props.match.params.did}/blank`);
    props.history.goBack();
  }
render(){
  return (
    <MyContext.Consumer>
      {context => (
        <div className="header">
          <Link to={`/shop/${this.props.match.params.did}`}>
            <img 
              className="company_logo" 
              src={this.state.logo} alt={context.dealer_details.name} />
            
            <div className="company_name"></div>
          </Link>
          <div className="dealer"><i className="fas fa-store"></i> {context.dealer_details.name}</div>
          <div className="spacer"></div>
          <div className="category_select_container" >
            <Select 
              options={this.state.categories}  
              placeholder={<div className="pholder">Browse category</div>}  
              className="category_select" 
              onChange={e => this.categoryChange(e,this.props)}
              value = {this.state.selectedOption}
            />
          </div>
          <div className="cart" >
            <Link to={`/shop/${this.props.match.params.did}/cart`}>
              <i className="fa fa-shopping-bag"></i><span className="cart_count lctc">{context.cart_items.length}</span>
            </Link>
          </div>
        </div>
      )}
    </MyContext.Consumer>
  )}
}

Header.contextType = MyContext;
export default Header;