import React, { Component } from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import $ from 'jquery';
import './Cart.scss';
import MyContext from '../MyContext.jsx';
// import ReCAPTCHA from "react-google-recaptcha";

export default class Cart extends Component {
  constructor(props) { 
    super(props);
    this.state = {
      dealer_token : this.props.match.params.did,
      name : '',
      mobile_no : '',
      address : '',
      delivery_type : 'H',
      note : ''
    };

    this.formChange = this.formChange.bind(this);
  }

  removeProduct(index,cart_items,updateCart){
    cart_items.splice(index, 1);
    updateCart();
    
  } 

   minusQty(item,updateCart){
    if(item.qty>1){
      item.qty--;
      updateCart();
    }
  } 

  changeQty(value,item,updateCart){
    item.qty = value;
    updateCart();
  }

  blurQty(value,item,updateCart){
    item.qty = value>0?value:1;
    updateCart();
  }

  formChange(e){
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  formSubmit(e,cart_items,updateCart,props){
    e.preventDefault();
    // console.log(this.state);
    // return;
    this.context.loading(1);
    const obj_this = this;
    window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITEKEY, {action: 'contact'}).then(function(token) {
      if (token) {
        // console.log(cart_items);
        const url = `${process.env.REACT_APP_SSS}/api/saveSalesOrder`;
        const data = Object.assign({},obj_this.state);
        data.cart_items = JSON.stringify(cart_items);
        const formdata = new FormData();
        for(var key in data){
          formdata.append(key,data[key]);
        }
        formdata.append('captcha_token',token);
        axios.post(url,formdata)
          .then(res => {
            console.log(res.data);
            if(res.data.status == "success"){
              cart_items.length = 0;
              updateCart();
              obj_this.setState({
                  name : "",
                  mobile_no : "",
                  address : "",
                  delivery_type : '',
                  note : ''
              });
              obj_this.context.loading(0);
              alert("Order placed successfully.");
              props.history.push(`/shop/${props.match.params.did}`);         
            }else{
              obj_this.context.loading(0);
              alert("Something went wrong.");
            }
          })
          .catch(err => console.log(err));
      }else{
        obj_this.context.loading(0);
        alert("Sorry something went wrong.");
      }
     });
  }
  render(){

    const Item = (context) =>{
       if(context.cart_items.length){
        return(
          context.cart_items.map((item,index) => (
            item.addons ?item.checked_addons = item.addons.filter(addon => addon.is_checked == 1):[],
          <div className="single_product" key={index} id={index}>

            <Link className="product_image" 
              to={`/shop/${this.props.match.params.did}/viewProduct/${item.product_id}`}
              style={{ backgroundImage: `url(${process.env.REACT_APP_CDN}/${item.image1})`}}
            >
            </Link>
            <div className="product_details">
              <Link className="product_name" 
                to={`/shop/${this.props.match.params.did}/viewProduct/${item.product_id}`}
              >{item.label}
              </Link>
              <div className="unit">{item.unit}</div>
              <div className="brand">Brand : {item.brand_name}</div>
              <div className="addons_container" 
                style={
                  {display: item.checked_addons.length?'inline-block':'none'}
                }
              >
              <div className="addons_title">Addons</div>
                {item.addons &&
                  item.checked_addons.map((addon,index) => (
                    <span><i className="fa fa-hamburger"></i> {addon.name} &nbsp; </span>
                  ))}
              </div>
              <div className="price"><i className="fa fa-rupee-sign"></i>{parseFloat(item.total*item.qty).toFixed(2)}</div>
            </div>
            <div className="actions_container">
              <div className="qty_container">
                <button onClick={() => {this.minusQty(item,context.updateCart)}}><i className="fa fa-minus"></i></button>
                <input className="product_qty" 
                  type="number" 
                  value={item.qty} 
                  onChange={e => this.changeQty(e.target.value,item,context.updateCart)}
                  onBlur={e=> this.blurQty(e.target.value,item,context.updateCart)}
                />
                <button onClick={() => {item.qty++;context.updateCart();}}><i className="fa fa-plus"></i></button>
              </div>
              <div className="product_remove_button" onClick={() => { this.removeProduct(index,context.cart_items,context.updateCart)}} ><i className="fa fa-trash"></i> REMOVE ITEM</div>
            </div>
          </div>
        )))
      }else{
        return(
          <div className="empty_cart">Cart empty</div>
        )
      }
    }
  return (
    <MyContext.Consumer>
      {context => (
        <div className="cart_block pages">
          <div className="cart_products_block">
            <div className="cart_products_container">
              <h5 className="cart_title">Cart ({context.cart_items.length} Items)</h5>
                 {Item(context)}
            </div>
          </div>
          <div className="billing_details_block" style={{visibility:context.cart_items.length?'visible':'hidden'}}>
            <form className="cart_details_block" 
              onSubmit={(e) => this.formSubmit(e,context.cart_items,context.updateCart,this.props)}>
              <div className="bill_title">Billing Details</div>
              <div className="amount_grid">
                <div className="total_amount_label">Total amount<br /></div>
                <div className="total_amount"><i className="fa fa-rupee-sign"></i>{parseFloat(context.cart_total).toFixed(2)}</div>
              </div>
              <div className="label_input_container">
                <label>Name<i className="required">*</i></label>
                <input className="customer_name" name="name" 
                  value={this.state.name}
                  autoComplete="off"
                  onChange={this.formChange}
                  required
                />
              </div>
              <div className="label_input_container">
                <label>Mobile<i className="required">*</i></label>
                <input className="mobile_no" name="mobile_no"
                  value= {this.state.mobile_no}
                  autoComplete="off"
                  pattern="[1-9]{1}[0-9]{9}"
                  onChange={this.formChange}
                  required

                />
              </div>
              <div className="label_input_container">
                <label>Delivery address<i className="required">*</i></label>
                <textarea  name="address"
                  value={this.state.address}
                  autoComplete="off"
                  onChange={this.formChange}
                  required
                />
              </div>
              <div className="label_input_container">
                <label>Delivery note</label>
                <input  name="note"
                  value={this.state.note}
                  autoComplete="off"
                  onChange={this.formChange}
                />
              </div>
              <div className="delivery_type_container" 
              style={{display:this.context.dealer_details.restaurant?'block':'none'}}>
                <label>
                  <input type="radio" name="delivery_type" value="H"
                    checked={this.state.delivery_type === 'H'}  
                    onChange={this.formChange}
                   />&nbsp;HOME DELIVERY
                </label>&nbsp;&nbsp;
                <label>
                  <input type="radio" name="delivery_type" value="T"
                    checked={this.state.delivery_type === 'T'}  
                    onChange={this.formChange}
                      />&nbsp;TAKE AWAY
                </label>
              </div>
              <div className="delivery_instruction" 
                style={{display:this.context.dealer_details.instruction?'block':'none'}}>* {this.context.dealer_details.instruction} *</div>
              <div className="captcha_policy_container">
                This site is protected by reCAPTCHA and the Google&nbsp;
                <a href="https://policies.google.com/privacy">Privacy Policy</a> and&nbsp;
                <a href="https://policies.google.com/terms">Terms of Service</a> apply.
              </div>
              
              <button type="submit" className="place_order_button">PLACE ORDER</button>
            </form>
          </div>
        </div>
      )}
    </MyContext.Consumer>
  )}
}
Cart.contextType = MyContext;

