import "../../assets/scss/styler_dynamic.scss";
import SingleProduct from './SingleProduct.jsx';
import "../../assets/scss/positions.scss";

function Products({products,did}){
 
  return (
      <div className="products_container">
        {products &&
          products.map((product,idx) => (
          <SingleProduct
            key={idx}
            product = {product}
            dealer_id = {did}
          />  
      ))}
              
    </div>  

  );
}

export default Products;
