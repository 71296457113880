import logo from "../../assets/images/Digistore_Logo_LQ_2.png";
import './Footer.scss';
import Select from 'react-select';
import {Link} from "react-router-dom";
import React, { Component } from "react";
import MyContext from '../MyContext.jsx';


class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
   
 
render(){
  return (
    <MyContext.Consumer>
      {context => (
        <div className="footer">
          <div className="dealer_details">
            <div className="dealer_name">{context.dealer_details.name}</div>
            <div className="address"> {context.dealer_details.address}</div>
            <a className="contact_no" href={`tel:${context.dealer_details.contact_no}`} style={{display:context.dealer_details.contact_no?'block':'none'}}><i className="fas fa-phone-alt"></i> {context.dealer_details.contact_no}</a>
            <a className="email_id" href={`mailto:${context.dealer_details.customer_care_email_id}`} style={{display:context.dealer_details.customer_care_email_id?'block':'none'}}><i className="fas fa-envelope"></i> {context.dealer_details.customer_care_email_id}</a>
          </div>
          <div className="social_media_container">
            <div className="find_us">Find us on,</div>
            <div className="social_media_icons">
              <a href={context.dealer_details.facebook_id} target="_blank" style={{fontSize : '18px'}}><i className="fab fa-facebook-f"></i></a>
              <a href={context.dealer_details.instagram_id} target="_blank" style={{fontSize : '20px'}}><i className="fab fa-instagram"></i></a>
              <a href={context.dealer_details.googlebusiness} target="_blank" style={{fontSize : '18px'}}><i className="fab fa-google"></i></a>
              <a href={`https://api.whatsapp.com/send?phone=+91${context.dealer_details.contact_no}`} target="_blank" style={{fontSize : '20px'}}><i className="fab fa-whatsapp"></i></a>
            </div>
          </div>
        </div>
      )}
    </MyContext.Consumer>
  )}
}
 
export default Footer;