import "../../assets/scss/styler_dynamic.scss";
import loading from "../../assets/images/loading.gif";
import React, { Component } from "react";
import axios from "axios";
import $ from 'jquery';
import SimpleImageSlider from "react-simple-image-slider";

import MyContext from '../MyContext.jsx';
import Products from '../Products/Products.jsx';
import './Home.scss';
import "../../assets/scss/positions.scss";
import ReactGA from 'react-ga';
ReactGA.initialize('279611736');
ReactGA.pageview(window.location.pathname + window.location.search);

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products : [],
      phrase : ''
    }
    this.images = [
        { url: "http://localhost/EKATRA_PROJECTS/Digistore24x7/Digistore24x7_CDN/product_img/office4/23img3.jpg" },
        { url: "http://localhost/EKATRA_PROJECTS/Digistore24x7/Digistore24x7_CDN/product_img/office4/22img1.jpg" },
        { url: "http://localhost/EKATRA_PROJECTS/Digistore24x7/Digistore24x7_CDN/product_img/office4/22img1.jpg" }
      ];
    this.offset_value = 0;
    this.product_XHR ={abort : ()=>{}};
    // this.showProducts = this.showProducts.bind(this)
  }

  componentDidMount(){ 
    this.showProducts();
  }

  searchProduct(e){
    this.offset_value = 0;
    const phrase = e.target.value;
    this.setState({
      phrase : phrase,
      products : []
    });
    // console.log(phrase);
    this.showProducts(phrase);
  }

  showMoreButton(){
    this.offset_value+=20;
    this.showProducts(this.state.phrase);
  }

  showProducts(phrase=''){
    // var phrase = phrase??'';
    $(".show_more_button ,.no_more_results").css("display","none");
    $(".loading_gif").css("display","inline-block");
    const url= `${process.env.REACT_APP_SSS}/api/getDealerProducts`;
    const data = {
     'dealer_token' : this.props.match.params.did,
     'offset_value' : this.offset_value,
     'search_phrase' : phrase
    }
    this.product_XHR.abort();
    this.product_XHR = $.ajax({
      url : url,
      data : data,
      type : "POST"
    }).done((response)=>{
        // console.log(response.data);
        const combined = this.state.products.concat(response);
        if(response.length<20){
          setTimeout( () => {
            $(".loading_gif").css("display","none");
            this.setState({
              products : combined
            });
            // $(".no_more_results").css("display","block");
          },300);
          $(".show_more_button").css("display","none");
        }else{
          $(".loading_gif").css("display","none");
          this.setState({
            products : combined
          });
          $(".show_more_button").css("display","inline-block");
        }
    }); 
  }


  render(){
  return (
    <MyContext.Consumer>
      {context => (
        <div className="products_block">
          <div style={{display : "none"}}>
            <SimpleImageSlider
              style={{ margin: '0 auto' }}
                width= "100%"
                height={300}
                images={this.images}
                showBullets= 'true'
                showNavs= 'true'
                useGPURender= 'true'
                navStyle= '1'
                navSize= '35'
                navMargin= '30'
                startIndex={0}
                slideDuration= '0.5'
                bgColor= '#000'
            />
          </div>
          <div className="title_container">
            <div><div className="line"></div></div>
            <div className="title">Explore Products</div>
            <div><div className="line"></div></div>
          </div>
          <div className="search_container">
            <input className="search_input" 
              placeholder="Search.."
              onChange={(e) => {this.searchProduct(e)}}
            />
            <div className="show_results_label">{this.state.phrase && this.state.products.length?"Showing results for \""+this.state.phrase+"\"":""}</div>
          </div>
          <Products 
            products = {this.state.products}
            did = {this.props.match.params.did}
          />
          <button className="show_more_button" onClick={(e) => {this.showMoreButton(e)}}>Show more products</button>
          <img src={loading} className="loading_gif" />
          <div className="no_more_results">No more results</div>
        </div>
      )}
    </MyContext.Consumer>
  )}
}

export default Home;
