import "../../assets/scss/styler_dynamic.scss";
import axios from "axios";
import React, { Component } from "react";
import $ from 'jquery';
import loading from "../../assets/images/loading.gif";

import Products from '../Products/Products.jsx';
import '../Home/Home.scss';
import "../../assets/scss/positions.scss";

class Category extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      products : [],
      category : '',
      phrase : ''
    };
    this.offset_value = 0;
    this.product_XHR={'abort' : ()=>{}};
  }
  
  componentDidMount(){
    this.showProducts();
  }

  searchProductsCategorywise(e){
    this.offset_value = 0;
    const phrase = e.target.value;
    this.setState({
      phrase : phrase,
      products : []
    });
    this.showProducts(phrase);
  }

  showMoreButton(){
    this.offset_value+=20;
    this.showProducts(this.state.phrase);
  }

  showProducts(phrase=''){
    // var phrase = phrase??'';
    $(".show_more_button, .no_more_results").css("display","none");
    $(".loading_gif").css("display","inline-block");
    const url= `${process.env.REACT_APP_SSS}/api/getDealerProducts/categoryWise`;
    const data = {
      'dealer_token' :  this.props.match.params.did,
      'category_id' : this.props.match.params.cid,
      'search_phrase' :  phrase,
      'offset_value' :  this.offset_value
    }

    this.product_XHR.abort();
    this.product_XHR = $.ajax({
      url : url,
      data : data,
      type : "POST"
    }).done((response)=>{
        // console.log(response);
        const combined = this.state.products.concat(response.products);
       if(response.products.length<20){
          setTimeout( () => {
            $(".loading_gif").css("display","none");
            this.setState({
              products : combined,
              category : response.category_name
            });
            // $(".no_more_results").css("display","block");

          },300);
          $(".show_more_button").css("display","none");
        }else{
          $(".loading_gif").css("display","none");
          this.setState({
            products : combined,
            category : response.category_name
          });
          $(".show_more_button").css("display","inline-block");
        }
    }); 
  }


  render(){
    return (
      <div className="products_block pages">
        <div className="title_container">
          <div><div className="line"></div></div>
          <div className="title">{this.state.category}</div>
          <div><div className="line"></div></div>
        </div>
        <div className="search_container">
          <input className="search_input" 
            placeholder="Search.."
            onChange={(e) => {this.searchProductsCategorywise(e)}}
          />
          <div className="show_results_label">{this.state.phrase && this.state.products.length?"Showing results for \""+this.state.phrase+"\"":""}</div>
        </div>
        <Products 
          products = {this.state.products}
          did = {this.props.match.params.did}
        />
      <button className="show_more_button" onClick={(e) => {this.showMoreButton(e)}}>Show more products</button>
      <img src={loading} className="loading_gif" />
      <div className="no_more_results">No more results</div>
      </div>
    )}
}

export default Category;
