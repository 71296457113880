import React, { Component } from "react";
import MyContext from './MyContext.jsx';

export default class MyProvider extends Component {
  constructor(props){
    super(props);
    const dealer_token = window.location.pathname.split('/')[2];
    this.cart_name = "DigiStoreCart_"+ dealer_token;
    this.state = {
      cart_items : JSON.parse(window.localStorage.getItem(this.cart_name))??[],
      cart_total : 0,
      dealer_details : {},
      show_loading : 0
    };
    var total = 0;
    for (var i=0;i<this.state.cart_items.length;i++){
        total= total + (parseFloat(this.state.cart_items[i]['qty'])*parseFloat(this.state.cart_items[i]['total']));
    }
    this.state.cart_total = total;
    this.setState({
        cart_total : total
    });
  }

    render() {
        return (
            <MyContext.Provider
                value={{
                    cart_items : this.state.cart_items,
                    updateCart : () => {
                        const cart_items = this.state.cart_items;
                        window.localStorage.setItem(this.cart_name,JSON.stringify(cart_items));
                        var total = 0;
                        for (var i=0;i<cart_items.length;i++){
                            total= total + (parseFloat(cart_items[i]['qty'])*parseFloat(cart_items[i]['total']));
                        }
                        this.setState({
                            cart_items : cart_items,
                            cart_total : parseFloat(total).toFixed(2)
                        });
                    },
                    cart_total : this.state.cart_total,
                    dealer_details : this.state.dealer_details,
                    setDealerDetails : (data) => {
                        // console.log(data);
                        this.setState({
                            dealer_details : data
                        });
                        // console.log(this.state.dealer_details);
                    },
                    show_loading : this.state.show_loading,
                    loading : (data) => {
                        this.setState({
                            show_loading : data
                        });
                    }
                }}
            >
                {this.props.children}
            </MyContext.Provider>
        );
    }
}