import {
  BrowserRouter,
  Route
} from "react-router-dom";
import React, { Component } from "react";
import MyProvider from './components/MyProvider.jsx';
import Loading from './components/Loading/Loading.jsx';
import Header from './components/Header/Header.jsx';
import Footer from './components/Footer/Footer.jsx';
import Home from './components/Home/Home.jsx';
import Category from './components/Category/Category.jsx';
import ViewProduct from './components/Products/ViewProduct.jsx';
import Cart from './components/Cart/Cart.jsx';
import Blank from './components/Blank.jsx';
import BottomNavbar from './components/BottomNavbar/BottomNavbar.jsx';
import "./assets/scss/styler_dynamic.scss";
import "./assets/scss/remove_defaults.scss";
import './App.scss';

class App extends Component {
  render() {
    return(
      <MyProvider>
        <BrowserRouter >
       
            <Route path="/shop/:did" component={Header} />
            <Route exact path="/shop/:did/blank" component={Blank} />
            <Route exact path="/shop/:did" component={Home} />
            <Route exact path="/shop/:did/category/:cid" component={Category} />
            <Route exact path="/shop/:did/viewProduct/:pid" component={ViewProduct}  />
            <Route exact path="/shop/:did/cart" component={Cart}  />
            <Route path="/shop/:did" component={Footer} />
            <Route path="/shop/:did" component={BottomNavbar} />
            <Route path="/shop/:did" component={Loading} />
            
      </BrowserRouter>
    </MyProvider>
    );
  }
}

export default App;
